import { ActorActionEnum } from "src/app/constants/actor-action.enum";
import { BadgeStatusEnum } from "src/app/constants/badge-status.enum";

/**
 * The status of an Acttor for Status Screen Actions
 * - This displays Status colour, and what actions are possible
 */
export enum ActorStatusEnum {
    /**
     * This actor SIGNED
     */
    Completed = "Completed",// 1,
    /**
     * this actor is next to sign/Approve/Download
     */
    Pending = "Pending",//2,
    /**
     * This actor is not yet signed, but not the next, it is waiting
     */
    Waiting = "Waiting",//3,
    /**
     * This actor has an email Error
     */
    Error = "Error",//4,
    /**
     * This actor has Triggered a Special Status:  Example: Rejected ||  Declined
     */
    TriggeredSpecialStatus = "TriggeredSpecialStatus",//5,
}

export enum MessageTypeEnum {
    MessageTypeUnspecified = "MessageTypeUnspecified",
    MessageTypeInvite = "MessageTypeInvite",
    MessageTypeInvitationRevoked = "MessageTypeInvitationRevoked",
    MessageTypeInvitationReminder = "MessageTypeInvitationReminder",
    MessageTypeSigningCompleted = "MessageTypeSigningCompleted",
    MessageTypeSigningRejected = "MessageTypeSigningRejected",
    MessageTypeInvitationExpired = "MessageTypeInvitationExpired",
    MessageTypePublicationSucceeded = "MessageTypePublicationSucceeded",
    MessageTypePublicationFailed = "MessageTypePublicationFailed",
    MessageTypeExpirationWarning = "MessageTypeExpirationWarning"

}
export enum EmailEventTypeEnum {

    UNKNOWN = "UNKNOWN",//0,
    REJECT = "REJECT",//1,
    HARDBOUNCE = "HARDBOUNCE",//2,
    SOFTBOUNCE = "SOFTBOUNCE",//3,
    ERROR = "ERROR",//4,
    DELIVERED = "DELIVERED",//5,
    OPENED = "OPENED",//6,
    OPEN = "OPEN",
    PROCESSED = "PROCESSED",
    CLICKED = "CLICKED",
    CLICK = "CLICK",
    ATTEMPT = "ATTEMPT",
}
export class EmailActivityLineModel {
    timestamp!: Date;
    usedEmail!: string;
    messageTypeKey!: MessageTypeEnum;
    eventTypeKey!: EmailEventTypeEnum;
    additionalMessage: string | null = null;
}
export class EditPersonModel {
    // GUID
    personGuid!: string;
    // display fields
    firstname!: string;
    prefix!: string;
    lastname!: string;
    personalMessage: string | null = null;
    verifyRealIdentity: boolean = false;
    identityProvider!: string;
    // editable fields
    language!: string;
    email!: string;
    phone!: string;
}

export class ActorSendGroupStatusInformationModel {
    // Core
    sendGroupGuid!: string;
    sendGroupName!: string;
    // Acts
    actors: ActorStatusInformationModel[] = [];
    // Actions
    allowedToChangeDeadline: boolean = false;
    allowedToChangeDeadlineDays: boolean = false;
    allowedToResendInvite: boolean = false;
    // Dates
    madeActiveDate?: Date;
    completionDate?: Date;
    // States
    isActive: boolean = false;
    isCompleted: boolean = false;
    // Deadlines
    deadline!: Date;
    deadlineDays!: number;
}
export class ActorStatusInformationModel {

    allowedToEdit: boolean = false;
    editPersonModel: EditPersonModel | null = null;
    allowedToChangeDeadline: boolean = false;
    allowedToChangeDeadlineDays: boolean = false;
    isNextActor: boolean = false;
    allowedToResendInvite: boolean = false;
    hasEmailError: boolean = false;

    actorStatus!: ActorStatusEnum;
    inviteId!: string;
    sendGroupId!: number;
    fullName!: string;
    email!: string;
    phone!: string;
    language!: string;
    message!: string;
    action!: ActorActionEnum;
    documentName!: string;
    deadline: Date | null = null;
    completionDate: Date | null = null;
    deadlineDays: number | null = null;
    declineMessage: string | null = null;
    emailActivityLines: EmailActivityLineModel[] = [];
    id!: string;

    grouped: boolean = false;
    overRuledStatus?: BadgeStatusEnum;
    order: number = 0;
    countOfAction: number = 0;
    countOfCredits: number = 0;
    countOfDocuments: number = 0;
    personGuid!: string;

    maxCompletionDate!: Date | null;
}


