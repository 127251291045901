import { Component, Directive, Input } from '@angular/core';
import { GroupedEditorActor } from 'src/app/models/requestEditor';
import { RequestStatusService } from 'src/app/components/requeststatus/requeststatus.service';
import { ActorActionEnum } from 'src/app/constants/actor-action.enum';
import { RequestBuilderService } from 'src/app/components/requestbuilder/request-builder.service';
import { SingleOrDefault } from 'src/app/_helpers/ArrayExtensionMethodHelper.functions';
import { TranslateService } from '@ngx-translate/core';
import { DocumentModel } from 'src/app/models/document.model';

@Directive()

export class BuilderSendgroupActionsTableClass {
  @Input() actorInSendgroup!: GroupedEditorActor;
  @Input() documents!: DocumentModel[];
  @Input() index: number = 0;
  @Input() sortingAllowed: boolean = false;
  @Input() showMenu: boolean = false;
  translations = <any>{};
  latestDeadline: Date = new Date();
  isCollapsed:boolean = false;
  
  

  constructor(
    protected requestStatusService: RequestStatusService, protected requestBuilderService: RequestBuilderService, protected translateService: TranslateService) {
      this.getTranslations();
      translateService.onLangChange.subscribe(() => {
        this.getTranslations();
      })
  }

  public getTranslations() {
    this.translateService.get([
      'Request.SendGroup.Document',
      'Request.SendGroup.Documents',
      'Request.SendGroup.AllDocuments',
      'Request.SendGroup.Action',
      'Request.SendGroup.Actions',
      'Request.Status.Credits'
    ]).subscribe(translation => {
      this.translations.requestSendGroupDocument = translation['Request.SendGroup.Document'];
      this.translations.requestSendGroupDocuments = translation['Request.SendGroup.Documents'];
      this.translations.requestSendGroupAllDocuments = translation['Request.SendGroup.AllDocuments'];
      this.translations.requestSendGroupAction = translation['Request.SendGroup.Action'];
      this.translations.requestSendGroupActions = translation['Request.SendGroup.Actions'];
      this.translations.requestStatusCredits = translation['Request.Status.Credits'];
    });
}

  public getActionsByActor() {
    if(this.actorInSendgroup.actionType){
      return this.translateService.instant("Request.Actions." + this.actorInSendgroup.actionType);
    } else if(this.actorInSendgroup.action) {
      return this.translateService.instant("Request.Actions." + this.actorInSendgroup.action);
    } else {
      return this.actorInSendgroup.countOfAction + " " + (this.actorInSendgroup.countOfAction === 1 ? this.translations.requestSendGroupAction : this.translations.requestSendGroupActions);
    }
  }

  public getDocumentsByActor() {
    if(this.actorInSendgroup.actionType === ActorActionEnum.Download){ 
      return this.translations.requestSendGroupAllDocuments;
    } else if(this.actorInSendgroup.documentName){
      return this.actorInSendgroup.documentName;
    } else if(this.actorInSendgroup.documentId) {
      return SingleOrDefault(this.documents, 'id', this.actorInSendgroup.documentId)?.name;
    } else {
      return this.actorInSendgroup.countOfDocuments + " " + (this.actorInSendgroup.countOfDocuments === 1 ? this.translations.requestSendGroupDocument : this.translations.requestSendGroupDocuments);
    }
  }

  public getCreditsByActor() {
    if(this.actorInSendgroup.credits !== null && this.actorInSendgroup.credits !== undefined){
      return this.actorInSendgroup.credits;
    } else {
      return this.actorInSendgroup.countOfCredits;
    }
  }

  public openEmailPreview() {
    return this.requestBuilderService.openEmailPreview(this.actorInSendgroup);
  }
}

@Component({
  selector: 'desktop-builder-sendgroup-action-table-row',
  templateUrl: './desktop_table_builder_sendgroup_actions.component.html',
})

export class BuilderSendgroupTableActionsDesktopComponent extends BuilderSendgroupActionsTableClass {
  constructor(
    protected override requestStatusService: RequestStatusService, protected override requestBuilderService: RequestBuilderService, protected override translateService: TranslateService) {
    super(requestStatusService, requestBuilderService, translateService);
  }
}