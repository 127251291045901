import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewerDataService } from '../../viewer-data.service';
import { DocumentApiClient } from 'src/app/services/document.apiclient';
import { DocumentForDownloadModel } from 'src/app/models/api/document-for-download.model';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  templateUrl: './display-request-completed.modal.html'
})
export class DisplayRequestcompletedModal {

  @Output('DownloadRequest') DownloadRequest = new EventEmitter();

  requestId!: string;
  documentsToShow: DocumentForDownloadModel[] = [];
  masterToggle: boolean = true;
  selected: DocumentForDownloadModel[] = [];
  expires!: string;

  constructor(public activeModal: NgbActiveModal, private viewerDataService: ViewerDataService,
    private downloadService: DownloadService, private documentApiClient: DocumentApiClient) {
    this.requestId = this.viewerDataService.get_RequestGuid;

    this.retrieveMyDocumentsForDisplay();
  }
  get anyDocumentsSelected(): boolean {
    return this.documentsToShow && this.documentsToShow.some(x => x.isSelected);
  }

  downloadRequest() {
    var documentsToDownload = this.documentsToShow.filter(model => model.isSelected);
    this.downloadService.download(this.requestId, documentsToDownload);
  }

  toggleAll() {
    this.masterToggle = !this.masterToggle
    this.documentsToShow.forEach((doc) => {
      doc.isSelected = this.masterToggle;
    });
  }
  toggle(newModel: DocumentForDownloadModel) {
    newModel.isSelected = !newModel.isSelected;
    if (this.masterToggle)
      this.masterToggle = !this.masterToggle
  }
  private retrieveMyDocumentsForDisplay() {
    this.documentApiClient.getForDownloadList(this.requestId).subscribe((data) => {
      this.documentsToShow = data;
    });
  }
}
