import { Component, EventEmitter, Injectable, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DocumentActor } from 'src/app/models/actors';
import { StatusRequest } from 'src/app/models/requests';
import { User } from 'src/app/models/user';
import { RequestStatuses } from 'src/app/constants/status.enum';
import { RequestStatusService } from '../../requeststatus.service';
import { RequestStatusActionsEnum } from 'src/app/constants/RequestStatusActions.enum';

@Injectable()
export abstract class BaseStatusActions implements OnDestroy {
  private requestSubscription!: Subscription;
  hasEditRights: boolean = false;
  showMSplugins: boolean = false;
  actor!: DocumentActor;
  dossierPersonId!: string;
  RequestId!: string;
  user!: User;
  statusIndicator!: string;
  timestamp!: string;
  translations: any = <any>{};
  requestType!: string;
  allowedToChangeOwnership: boolean = false;
  requestGuid!: string;
  request: StatusRequest = {} as StatusRequest;

  requestStatusActionsEnum = RequestStatusActionsEnum;

  constructor(protected requestStatusService: RequestStatusService) {
    this.requestSubscription = this.requestStatusService.selectedRequest$.subscribe(request => {
      if(Object.keys(request).length === 0) return;
      this.request = request;
      this.requestGuid = request.id;
      this.allowedToChangeOwnership = this.requestStatusService.isUserAllowedToChangeOwnership();
      this.hasEditRights = this.requestStatusService.hasUserEditRights();
      this.showMSplugins = this.requestStatusService.showingMSPlugins();
    });
  }

  ngOnDestroy(): void {
    this.requestSubscription.unsubscribe();
  }

  purgeAllowed() {
    let purgeAllowedStatuses: string[] = RequestStatuses.ThatCanBe_Purged;
    if (purgeAllowedStatuses.includes(this.request.status)) {
      return true;
    }
    return false;
  }

  deleteAllowed() {
    let deleteAllowedStatuses: string[] = RequestStatuses.ThatCanBeDeleted;
    if (deleteAllowedStatuses.includes(this.request.status)) {
      return true;
    }
    return false;
  }

  withdrawAllowed() {
    let withdrawAllowedStatuses: string[] = RequestStatuses.ThatCanBeWithdrawn;
    if (withdrawAllowedStatuses.includes(this.request.status)) {
      return true;
    }
    return false;
  }

  purgedActionsAllowed() {
    let purgedActionsStatuses: string[] = RequestStatuses.ThatCanBeRestored;
    if (purgedActionsStatuses.includes(this.request.status)) {
      return true;
    }
    return false;
  }

  public applyAction(action: RequestStatusActionsEnum) {
    switch (action) {
      case RequestStatusActionsEnum.Download:
        this.requestStatusService.downloadRequest(this.requestGuid);
        break;
      case RequestStatusActionsEnum.Export:
        this.requestStatusService.exportRequest(this.requestGuid);
        break;
      case RequestStatusActionsEnum.Purge:
        this.requestStatusService.purgeRequest(this.requestGuid);
        break;
      case RequestStatusActionsEnum.Delete:
        this.requestStatusService.deleteRequest(this.requestGuid);
        break;
      case RequestStatusActionsEnum.Withdrawn:
        this.requestStatusService.openWithdrawModal(this.request);
        break;
      case RequestStatusActionsEnum.Restore:
        this.requestStatusService.restoreRequest(this.request);
        break;
      case RequestStatusActionsEnum.BecomeOwner:
        this.requestStatusService.becomeOwner(this.requestGuid);
        break;
      case RequestStatusActionsEnum.Viewlog:
        this.requestStatusService.openStatusViewLogModal(this.request);
        break;
      default:
        break;
    }
  }
}

@Component({
  selector: 'desktop-status-actions',
  templateUrl: './desktop-status-actions.component.html'
})
export class DesktopStatusActions extends BaseStatusActions {
  constructor(protected override requestStatusService: RequestStatusService) {
    super(requestStatusService);
  }
}

@Component({
  selector: 'mobile-status-actions',
  templateUrl: './mobile-status-actions.component.html'
})
export class MobileStatusActions extends BaseStatusActions {
  constructor(protected override requestStatusService: RequestStatusService) {
    super(requestStatusService);
  }
}