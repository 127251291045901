import { ActorActionEnum } from "src/app/constants/actor-action.enum";

export class BaseActorCreateClass {
    /** Guid */
    personGuid!: string;
    /** Guid */
    documentGuid!: string;
    /** For what action */
    action!: ActorActionEnum;
    /**
     * IF created by placeholder, send it to BE
     */
    workflowPlaceholderId: number | null = null;
    /**
     * Array of signature providers that are allowed for the qualified signature. Empty is every signature provider.
     */
    allowedQtsps: string[] | null = null;

    /**
     * Required IdentityProvider for this, USED FOR VIDUA
     */
    identityProvider: string | null = null;

}

/**
* Model for the Actor Api-Client (controller) to CREATE a new actor
*/
export class ActorCreateModel extends BaseActorCreateClass {
    /** Guid */
    requestGuid!: string;
}
/**
* Model for the Actor Api-Client (controller) to CREATE BULK new actors
*/
export class BulkActorCreateModel {
    /** Guid */
    requestGuid!: string;
    newActions: BaseActorCreateClass[] = [];
}
