import { SafeUrl } from "@angular/platform-browser";
import { DocumentActor } from "./actors";

export class DocumentModel {
    id!: string;
    name!: string;
    documentType!: string;
    fileSize!: string;
    documentStatus!: string;
    filetype!: string;
    pages: number = 1;
    attachment: boolean = false;
    progress: number = 0;
    containsPreviousSignatures: boolean = false;
    pageSizes!: string;
    preloadedPages!: PreloadedPages[];
    actors!: DocumentActor[];
    dossierIndex!: number;
    error?: string;
    uploadProgress: number = 100;
    workflowPlaceholderId?: number | null;
    filename!: string;
    thumbnails!: Thumbnail[];
}

export class PageSize {
    X!: number;
    Y!: number;
}

export class PreloadedPagesByDocument {
    documentId!: string;
    preloadedPages!: PreloadedPages[];
}

export class PreloadedPages {
    PageId!: number;
    PageUrl: SafeUrl | null = null;
}
export class Thumbnail {
    PageId!: number;
    ThumbnailUrl: SafeUrl | null = null;
}
export class DocumentInfoForToast {
    Guid!: string;
    Name!: string;
}
export class DocumentIsLockedModel extends DocumentInfoForToast {
    Seconds!: number;
}
