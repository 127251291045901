<ng-container *ngFor="let document of tableData">
  <div class="d-flex align-items-center justify-content-center accordion-mobile-item">
    <div class="ps-3 w-100 align-items-center justify-content-center">
      <span class="lato-font">{{document.name}}</span><br />
      <span class="text-secondary mobile-text" translate="Request.Document.{{document.documentType}}"></span>
    </div>
    <div class="col-1">
      <span class="float-end">
        <button type="button" class="btn bg-transparent border-0" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi bi-three-dots"></i>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item pt-1 pb-1"  (click)="performActionForAnId('openPreview', null, document.identifier)" translate="Button.ShowDocument">
            </a>
          </li>
        </ul>
      </span>
    </div>
  </div>
  <ng-container matColumnDef="message">
    <td *matCellDef="let documentWithError" mat-cell [attr.colspan]="displayedColumns.length">
      <div class="break-words">
        <div class="alert alert-danger p-1 mt-0 white-space" role="alert">
          {{documentWithError.logErrorMessage}}
        </div>
      </div>
    </td>
  </ng-container>
  <!-- Display data and declare specific embedded component -->
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
  <tr mat-row *matRowDef="let row; columns: ['message']; when: hasErrorMessage"></tr>
</ng-container>