import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RequestItemInDashboard } from '../../models/dashboard';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardApiClient {

  constructor(private httpClient: HttpClient) { }

  configUrlDashboard = environment.apiUrl + "/dashboard";
  configUrlOrganisationdocuments = environment.apiUrl + "/dashboard/ForOrganisation";

  async getDashboard() {
    let params = new HttpParams().set('includeExpired', true)
    return firstValueFrom(this.httpClient.get<RequestItemInDashboard[]>(this.configUrlDashboard, { params }));
  }

  async getRequestsFromOrg() {
    let params = new HttpParams().set('includeExpired', true);
    return firstValueFrom(this.httpClient.get<RequestItemInDashboard[]>(this.configUrlOrganisationdocuments, { params }));
  }

}
