export class PKIUrls{
    //Atliassian
    public static PatchNotes :string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1232437262";
    public static KnowledgeBase :string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/overview";
    public static CreateSupportTicket :string = "https://pkisigning.atlassian.net/servicedesk/customer/portal/2";
    public static GiveFeedback :string = "https://pkisigning.atlassian.net/servicedesk/customer/portal/2/group/9/create/66";
    public static AddressBookUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1203699731#4.4-Adresboek";
    public static DetachedCertificateUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1203830787#4.-Validatie";
    public static ClearancelevelUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1203699731#5.3.2-Vertrouwensniveaus";
    public static AuthenticationSecurityUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/279576598#4.-Tweestapsauthenticatie";
    public static ShareRequestWorkgroupUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/875298827/Dossiers+delen+met+je+werkgroep";
    public static PlatformApiUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1203699731#4.2-Platform-API";
    public static IdentificationProviderUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1204092950";
    public static UsersUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1203699731#5.2-Gebruikers";
    public static WorkgroupsUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1203699731#5.3-Werkgroepen";
    public static RequestBuilderMoreInformationUrl: string = "https://pkisigning.atlassian.net/wiki/spaces/PKISUPPORT/pages/1557692419/Media#Verzendgroepen";

    //Microsoft
    public static BlobStorageUrl: string = "https://learn.microsoft.com/en-us/azure/storage/common/storage-configure-connection-string";

    //Simplicate
    public static SimplicateUrl: string = "https://support.simplicate.nl/nl/articles/6693108-api-key-aanmaken-en-verwijderen";

    //Salesforce
    public static SalesforceUrl: string = "";
}