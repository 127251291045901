<div class="row w-100 d-none d-lg-flex p-0 align-items-center"
    [ngClass]="{'text-primary fw-bold cursor-pointer': actorInSendgroup.grouped }">
    <div class="col-4 firstcol text-truncate">
        <span *ngIf="actorInSendgroup.grouped" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"
            class="cursor-pointer">
            {{actorInSendgroup.name}}
            <i class="bi" [ngClass]="{ 'bi-caret-down-fill': !isCollapsed, 'bi-caret-up-fill': isCollapsed}"></i>
        </span>
        <span *ngIf="!actorInSendgroup.grouped">
            {{actorInSendgroup.name}}
        </span>
    </div>
    <div class="col-2 text-truncate">{{getActionsByActor()}}</div>
    <div class="col-4 text-truncate">{{getDocumentsByActor()}}</div>
    <div class="col-1 text-truncate">{{getCreditsByActor()}}</div>
    <div class="col-1 text-end">
        <span class="float-end">
            <div class="float-end ms-2 base-font-color"><i class="bi bi-grip-vertical bi-large cursor-change-order"></i>
            </div>
            <div *ngIf="showMenu" class="btn-group">
                <button type="button" class="btn bg-transparent border-0 p-0" (click)="$event.stopPropagation();"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="bi bi-three-dots bi-large"></i>
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item pt-1 pb-1" (click)="$event.stopPropagation(); openEmailPreview()"
                            translate="Button.ShowInvite"></a></li>
                </ul>
            </div>
        </span>
    </div>
</div>