import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

/**
 * The Api Client for the ActorActionssController
 */
@Injectable({
  providedIn: 'root'
})
export class ActorActionsApiClient {

  constructor(private httpClient: HttpClient) { }

  /** INITIATES singing, to later be finished with SignFinished */
  async initiate_PDF_Signing(requestGuid: string, actorInviteId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/initiate-signing", {}, httpOptions);
  }

  /** Finishes the initiated Signing */
  finish_PDF_Signing(requestGuid: string, actorInviteId: string, signDoneSession: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/finish-signing", signDoneSession, httpOptions);
  }
  /** INITIATES singing, to later be finished with SignFinished */
  async initiate_XBRL_Signing(requestGuid: string, actorInviteId: string) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/initiate-signing-xbrl", {}, httpOptions);
  }

  /** Finishes the initiated Signing */
  finish_XBRL_Signing(requestGuid: string, actorInviteId: string, signDoneSession: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/finish-signing-xbrl", signDoneSession, httpOptions);
  }

  /**
   *  Allows an Actor to reject humanity and embrace MONKE... i mean reject the Action.. yeah the Action ofcourse!
   * @param requestGuid relevant for what request to verify/Reject
   * @param actorInviteId Actor Rejecting
   * @returns (y)
   */
  Reject(requestGuid: string, actorInviteId: string, rejectionReason: string) {
    return this.httpClient.post(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/reject", { rejectionReason: rejectionReason });
  }
  /**
   * 
   * @param dossierId 
   * @param actorInviteId 
   * @returns 
   */
  approve(requestGuid: string, actorInviteId: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/approve", {});
  }
  /**
   * Executes a 'Linking' Action for the given Actor/Request
   * @param dossierId 
   * @param actorInviteId 
   * @returns 
   */
  link(requestGuid: string, actorInviteId: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/link", {});
  }

  /**
   * 
   * @param dossierId 
   * @param actorInviteId 
   * @returns 
   */
  adopt(requestGuid: string, actorInviteId: string, skipAdoption: boolean, adoptionDate: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/adopt", { SkipAdoption: skipAdoption, AdoptionDate: adoptionDate });
  }
  /**
   * 
   * @param dossierId 
   * @param actorInviteId 
   * @returns 
   */
  file(requestGuid: string, actorInviteId: string, receiverId: string) {
    return this.httpClient.post<any>(environment.apiUrl + "/actor-actions/" + requestGuid + "/" + actorInviteId + "/file", { receiverId: receiverId });
  }



}
