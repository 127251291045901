import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as signalR from "@microsoft/signalr";
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { LoggingService } from './services/logging.service';
import { ViewerHubEnum } from './constants/viewerHub.enum';
import { LockDetailsModel } from './models/locked_details';
// In app component we require signalr

@Injectable({
  providedIn: 'root'
})

export class ViewerHub {
  accessToken: any;

  constructor(private oidcSecurityService: OidcSecurityService, private loggingService: LoggingService) { }

  private viewerHub!: signalR.HubConnection;
  private retrys: number = 0;
  public starting: boolean = false;
  private viewerEventSubject = new Subject<string[]>();
  public viewerEvent = this.viewerEventSubject.asObservable();
  private requestSubject = new Subject<Request>();
  public request$ = this.requestSubject.asObservable();

  public async start() {
    this.oidcSecurityService.getAccessToken().subscribe((token) => this.accessToken = token);

    if (this.starting) return;

    this.starting = true;

    let slot = document.cookie.split('; ').find(row => row.startsWith("slot="))?.split('=')[1];
    if (slot)
      slot = "x-ms-routing-name=" + slot + "&";
    else
      slot = "";

    this.viewerHub = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiUrl + "/viewerhub?" + slot + "access_tokent=" + this.accessToken)
      .withAutomaticReconnect([0, 1000, 5000, 10000, 60000])
      .build();
    this.viewerHub.serverTimeoutInMilliseconds = 240000; //4 minutes

    this.viewerHub.on(ViewerHubEnum.RequestUpdated, (request) => {
      this.viewerEventSubject.next([ViewerHubEnum.RequestUpdated, request]);
    });

    this.viewerHub.on(ViewerHubEnum.ActionUpdated, (request) => {
      this.viewerEventSubject.next([ViewerHubEnum.ActionUpdated, request]);
    });
    this.viewerHub.on(ViewerHubEnum.DocumentLockedForSigning, (documentGuid, model: LockDetailsModel) => {
      this.viewerEventSubject.next([ViewerHubEnum.DocumentLockedForSigning, documentGuid, model]);
    });
    this.viewerHub.on(ViewerHubEnum.DocumentUnlocked, (documentGuid) => {
      this.viewerEventSubject.next([ViewerHubEnum.DocumentUnlocked, documentGuid]);
    });


    let currentClass = this;
    await this.viewerHub.start()
      .catch((e: any) => {
        currentClass.viewerEventSubject.next(['error', e]);
        this.starting = false;
      });

  }

  async stop() {
    if (this.viewerHub)
      await this.viewerHub.stop().catch((error) => this.loggingService.logException(error));
    this.starting = false;
  }

  getConnectionState() {
    if (this.viewerHub !== undefined) {
      return this.viewerHub.state;
    } else {
      this.retrys++;
      if (this.retrys > 5) {
        this.retrys = 0;
        this.starting = false;
        this.start();
      }
      return false;
    }
  }

  registerForRequestUpdates(requestGuid: string) {
    this.viewerHub.invoke(ViewerHubEnum.RegisterForRequestUpdates, requestGuid);
  }

  signStarted(documentGuid: string, inviteGuid: string) {
    this.viewerHub.invoke(ViewerHubEnum.SignStarted, documentGuid, inviteGuid, null);
  }

  signFailed(documentGuid: string, inviteGuid: string) {
    this.viewerHub.invoke(ViewerHubEnum.SignFailed, documentGuid, inviteGuid);
  }

  signDone(documentGuid: string, inviteGuid: string) {
    this.viewerHub.invoke(ViewerHubEnum.SignDone, documentGuid, inviteGuid);
  }

  signStatus(documentGuid: string) {
    this.viewerHub.invoke(ViewerHubEnum.SignStatus, documentGuid);
  }
}
