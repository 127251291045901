import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'closable-wrapper',
    templateUrl: './closable-wrapper.component.html',
    styleUrls: ['./closable-wrapper.component.scss']
})
export class ClosableWrapperComponent {
    @Input() collapse: boolean = true; // false = expand, true = collapse
    @Input() floatIconRight: boolean = false; // false = arrow after text, true = float end of line
    @Input() set help(text: string) {
        if (text) this.helpText = this.translateService.instant(text);
    }
    @Input() title!: any;
    private _sessionStorageName!: string;
    @Input() set sessionStorageName(value: string){
        this._sessionStorageName = value;
        if(value){
            let localStorageCollapsed = localStorage.getItem(this._sessionStorageName);
            if (localStorageCollapsed) {
                this.collapse = JSON.parse(localStorageCollapsed);
            } else {
                if(!this.collapse){
                    this.collapse = false;
                }
                this.collapse = this.collapse;
            }
            this.isCollapsed = this.collapse;
        }
    };
    public isCollapsed!: boolean;
    helpText: string = '';

    constructor(private translateService: TranslateService) {
        this.isCollapsed = this.collapse;
    }

    public setCollapse(){
        this.isCollapsed = !this.isCollapsed;
        if(this._sessionStorageName){
            localStorage.setItem(this._sessionStorageName, this.isCollapsed.toString());
        }
    }
}