import { ClearancelevelEnum } from "../constants/clearance-levels.enum";
import { DocumentActor } from "./actors";
import { DocumentModel } from "./document.model";
import { SendGroupModel } from "./sendgroup.model";

export interface RequestModel {
    StatusItems: any;
    signerNote: any;
    recipientNote: any;
    accorderNote: any;
    reasons: any;
    ownerName: any;
    workgroupId: any;
    emailActivity: any;
    documentInfo: DocumentModel[];
    documentOwner: any;
    owner: any;
    id: string;
    jobName: string;
    status: string;
    previousStatus: string;
    userStatus: string;
    type: string;
    dossierType: string;
    created: Date;
    lastUpdate: Date;
    expires?: Date;
    signers: string;
    signed: string;
    actors: DocumentActor[];
    ownerFullName: string;
    documentsInDossier: number;
    actionRequired: boolean;
    /**
     * A guid representing the unique identifier of a workgroup
     */
    workgroup: string;
    workgroupName: string;
    clearancelevel: ClearancelevelEnum;
    deadline?: Date;
    expireDeadline?: Date;
    deleteDeadline?: Date;
    dossierPersons: DocumentActor[];
    _translatedType: any;
    _translatedPreviousAction: any;
    _translatedNextAction: any;
    _currentUsersAction?: DocumentActor;
    workflowId?: string;
    reservedCredits: number;
    previousAction: string;
    previousActor: string;
    nextAction: string;
    nextActor: string;
    progress: string;
    taxAmount: any;
    taxPaymentStatus: string;
    builderSendgroups?: SendGroupModel[];
    sendDate: Date | string;
    stage: string;

    //Rejection fields
    isRejected: boolean;
    rejectionReason: string;
    rejectionUserName: string;
    // Withdrawn Fields
    isWithdrawn: boolean;
    withdrawnReason: string;
    withdrawnUserName: string;
}

export interface Requests extends Array<RequestModel> {
}

export interface SignalrRequest extends RequestModel {
    guid: any;
    statusItem: any;
    workgroupName: string;
}

export interface DashboardRequest extends RequestModel {
}

export interface StatusRequest extends RequestModel {

}

export interface StatusRequestInfo {
    ownerName: any;
    id: string;
    jobName: string;
    status: string;
    dossierType: string;
    reservedCredits: number;
}

export interface BuilderRequest extends RequestModel {
    document?: DocumentModel[];
    validationSummary?: ValidationSummaryClass;
}


export class ValidationSummaryClass {
    actionValidationSummary: actionValidationSummary = new actionValidationSummary();
    hasValidationErrors: boolean = false;
    passedValidations: boolean = true;
    sendGroupValidationSummary: sendGroupValidationSummary = new sendGroupValidationSummary();
    validationErrors: validationErrors[] = [];
}


export interface validationErrors {
    code: number;
    message: string;
}

export class actionValidationSummary {
    actionRules: any[] = [];
    actions: string[] = [];
    allowsMoreActions: boolean = false;
    blockedActions: string[] = [];
    documents: any[] = [];
    hasValidationErrors: boolean = false;
    passedValidations: boolean = true;
    persons: any[] = [];
    possibleActions: string[] = [];
    request: any = {};
    validationErrors: validationErrors[] = [];
}

export class sendGroupValidationSummary {
    hasValidationErrors: boolean = false;
    passedValidations: boolean = true;
    persons: any[] = [];
    request: any = {};
    sendGroupRules: any[] = [];
    sendGroups: any[] = [];
    validationErrors: validationErrors[] = [];
}