import { Component, EventEmitter, Injectable, Input, Output } from "@angular/core";
import { OwlDateTimeIntl } from "@danielmoncada/angular-datetime-picker";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    constructor(private tranlationService: TranslateService) {
        super();
    }
  /** A label for the up second button (used by screen readers).  */
  override upSecondLabel= this.tranlationService.instant("OwlDateTime.UpSecondLabel");

  /** A label for the down second button (used by screen readers).  */
  override downSecondLabel = this.tranlationService.instant("OwlDateTime.MinusASecond");

  /** A label for the up minute button (used by screen readers).  */
  override upMinuteLabel= this.tranlationService.instant("OwlDateTime.AddAMinute");

  /** A label for the down minute button (used by screen readers).  */
  override downMinuteLabel= this.tranlationService.instant("OwlDateTime.MinusAMinute");

  /** A label for the up hour button (used by screen readers).  */
  override upHourLabel= this.tranlationService.instant("OwlDateTime.AddAHour");

  /** A label for the down hour button (used by screen readers).  */
  override downHourLabel= this.tranlationService.instant("OwlDateTime.MinusAHour");

  /** A label for the previous month button (used by screen readers). */
  override prevMonthLabel= this.tranlationService.instant("OwlDateTime.PreviousMonth");

  /** A label for the next month button (used by screen readers). */
  override nextMonthLabel= this.tranlationService.instant("OwlDateTime.NextMonth");

  /** A label for the previous year button (used by screen readers). */
  override prevYearLabel= this.tranlationService.instant("OwlDateTime.PreviousYear");

  /** A label for the next year button (used by screen readers). */
  override nextYearLabel= this.tranlationService.instant("OwlDateTime.NextYear");

  /** A label for the previous multi-year button (used by screen readers). */
  override prevMultiYearLabel= this.tranlationService.instant("OwlDateTime.Previous21Years");

  /** A label for the next multi-year button (used by screen readers). */
  override nextMultiYearLabel= this.tranlationService.instant("OwlDateTime.Next21Years");

  /** A label for the 'switch to month view' button (used by screen readers). */
  override switchToMonthViewLabel= this.tranlationService.instant("OwlDateTime.ChangeToMonthView");

  /** A label for the 'switch to year view' button (used by screen readers). */
  override switchToMultiYearViewLabel= this.tranlationService.instant("OwlDateTime.ChooseMonthAndYear");

  /** A label for the cancel button */
  override cancelBtnLabel= this.tranlationService.instant("OwlDateTime.Cancel");

  /** A label for the set button */
  override setBtnLabel= this.tranlationService.instant("OwlDateTime.Set");

  /** A label for the range 'from' in picker info */
  override rangeFromLabel= this.tranlationService.instant("OwlDateTime.From");

  /** A label for the range 'to' in picker info */
  override rangeToLabel= this.tranlationService.instant("OwlDateTime.To");

  /** A label for the hour12 button (AM) */
  override hour12AMLabel= this.tranlationService.instant("OwlDateTime.AM");

  /** A label for the hour12 button (PM) */
  override hour12PMLabel= this.tranlationService.instant("OwlDateTime.PM");
};

@Component({
    selector: 'datetime-picker',
    templateUrl: './datetime-picker.component.html'
})
export class DateTimePickerComponent {
    private minDeadlineDays: number = 1;
    private defaultDeadlineDays: number = 7;
    private minDeadlineYears: number = 1;
    private stepsByMinute = 1;

    @Input() previousDeadlineDate!: Date;
    @Output() changeSendDate: EventEmitter<Date> = new EventEmitter<Date>();

    public minDate = new Date(new Date().setDate(new Date().getDate() + this.minDeadlineDays));
    public maxDate = new Date(new Date().setFullYear(new Date().getFullYear() + this.minDeadlineYears));
    public startAt = new Date(new Date().setDate(new Date().getDate() + this.defaultDeadlineDays));
    public stepMinute = this.stepsByMinute;
    
    constructor() {
        
    }

    public changeSendDeadline(event: any){
        this.changeSendDate.emit(event);
    }
}