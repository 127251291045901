import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export const EmailPreviewModalOptions: NgbModalOptions = {
  keyboard: false,
  centered: true,
  size: 'lg'
};

@Component({
  selector: 'app-emailpreviewdialog',
  templateUrl: './email-preview-dialog.component.html'
})
export class EmailPreviewDialogComponent implements OnInit {
  @Input() emailTemplate!: string;
  emailPreview: SafeHtml = "";

  constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer, private ref: ChangeDetectorRef) {
    // Must not be empty. It's approved by a comment.
  }

  async ngOnInit(): Promise<void> {
    await this.renderHtml();
    this.ref.detectChanges()
    let tempFixInterval = setInterval(() => {
      this.renderHtml();
      clearInterval(tempFixInterval);
    }, 2000);
  }
  renderHtml() {
    this.emailPreview = this.sanitizer.bypassSecurityTrustHtml(this.emailTemplate);
  }

  close() {
    this.activeModal.close();
  }
}
