import { Pipe, PipeTransform } from '@angular/core';
import { DataTypeEnum } from '../constants/data-type.enum';
import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { byteSizePipe } from './byteSize.pipe';

/**
 * Parse value based on the specified data type.
 */
@Pipe({
    name: 'valueByTypePipe',
    pure: false
})
export class ValueByTypePipe implements PipeTransform {
    constructor(public translateService: TranslateService) { }

    transform(value: any, type: DataTypeEnum): any {
        if (value === undefined) return;
        switch (type) {
            case DataTypeEnum.General:
                return value;
            case DataTypeEnum.Guid:
                return value;
            case DataTypeEnum.ActorAction:
                return this.translateService.instant('dashboard.activitylist.actions.' + value);
            case DataTypeEnum.DateTime:
                return formatDate(value, 'dd-MM-yy HH:mm', 'en-US');
            case DataTypeEnum.DocumentType:
                return this.translateService.instant('Request.Document.' + value);
            case DataTypeEnum.FileSize:
                let byteSize = new byteSizePipe();
                return byteSize.transform(value);
            case DataTypeEnum.RequestType:
                return this.translateService.instant('Type.' + value);
            case DataTypeEnum.Status:
                return this.translateService.instant('Status.' + value);
            case DataTypeEnum.Stage:
                if (value.toLocaleLowerCase().startsWith("sendgroup")) {
                    let sendGroupStringArray = value.split(" ");
                    if(sendGroupStringArray.length > 1){
                        return this.translateService.instant('Request.Send.' + sendGroupStringArray[0], { number: sendGroupStringArray[1] });
                    } else {
                        return this.translateService.instant('Request.Send._' + sendGroupStringArray[0]);
                    }
                }
                    
                if(this.translateService.instant('dashboard.activitylist.actions.' + value) === 'dashboard.activitylist.actions.' + value){
                    return value;
                } else {
                    return this.translateService.instant('dashboard.activitylist.actions.' + value);
                }

                // Specific types for sendgroups
            case DataTypeEnum.SendDate:
            case DataTypeEnum.CompletionDate:
                return formatDate(value, 'dd-MM-yyyy HH:mm', 'en-US');
            case DataTypeEnum.PlusDays:
                return "+"+value+" "+ this.translateService.instant('Request.Status.' + ((value === 1) ? "Day" : "Days"));

            default:
                return value;
        }
    }
}