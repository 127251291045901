import { Component, Directive, Input } from '@angular/core';
import { RequestStatusService } from 'src/app/components/requeststatus/requeststatus.service';
import { ActorActionEnum } from 'src/app/constants/actor-action.enum';
import { RequestBuilderService } from 'src/app/components/requestbuilder/request-builder.service';
import { TranslateService } from '@ngx-translate/core';
import { DocumentModel } from 'src/app/models/document.model';
import { ActorStatusEnum, ActorStatusInformationModel } from 'src/app/models/api/actor-status-information.model';
import { DataTypeEnum } from 'src/app/constants/data-type.enum';
import { BadgeStatusEnum } from 'src/app/constants/badge-status.enum';
import { RequestStatusActionsEnum } from 'src/app/constants/RequestStatusActions.enum';

@Directive()

export class MobileRequestStatusSendgroupActionsTableClass {
  @Input() actorInSendgroup!: ActorStatusInformationModel;
  @Input() documents!: DocumentModel[];
  @Input() index: number = 0;
  @Input() sortingAllowed: boolean = false;
  @Input() itemGrouped: boolean = false;
  @Input() sendgroupStatus!: BadgeStatusEnum;
  @Input() first: boolean = false;
  @Input() last: boolean = false;
  @Input() overRuledStatus?: BadgeStatusEnum;
  translations = <any>{};
  latestDeadline: Date = new Date();
  isCollapsed:boolean = false;

  private InfoStyle = "info";
  private SecondaryStyle = "secondary";
  private SuccessStyle = "success";
  private WarningStyle = "warning";
  private DangerStyle = "danger";
 
  constructor(
    protected requestStatusService: RequestStatusService, protected requestBuilderService: RequestBuilderService, protected translateService: TranslateService) {
      this.getTranslations();
      translateService.onLangChange.subscribe(() => {
        this.getTranslations();
      });
  }

  public getTranslations() {
    this.translateService.get([
      'Request.Status.Document',
      'Request.Status.Documents',
      'Request.Status.AllDocuments',
      'Request.Status.Action',
      'Request.Status.Actions'
    ]).subscribe(translation => {
      this.translations.requestStatusDocument = translation['Request.Status.Document'];
      this.translations.requestStatusDocuments = translation['Request.Status.Documents'];
      this.translations.requestStatusAllDocuments = translation['Request.Status.AllDocuments'];
      this.translations.requestStatusAction = translation['Request.Status.Action'];
      this.translations.requestStatusActions = translation['Request.Status.Actions'];
    });
  }

  public get DataTypeEnum() {
    return DataTypeEnum;
  }

  public convertEventStatusToBadgeStatus(): any {
    return this.sendgroupStatus;
  }

  public getActionsByActor() {
    if(this.actorInSendgroup.countOfAction && !this.itemGrouped) {
      // count of actions in lowercase
      return this.actorInSendgroup.countOfAction + " " + (this.actorInSendgroup.countOfAction === 1 ? this.translations.requestStatusAction : this.translations.requestStatusActions).toLowerCase();
    } else if(this.itemGrouped) {
      return this.actorInSendgroup.action;
    } else {
      return this.actorInSendgroup.action;
    }
  }

  public getDocumentsByActor() {
    if(this.actorInSendgroup.action === ActorActionEnum.Download){ 
      return this.translations.requestStatusAllDocuments;
    } else if (this.actorInSendgroup.countOfDocuments && !this.itemGrouped) {
      // count of documents in lowercase
      return this.actorInSendgroup.countOfDocuments + " " + (this.actorInSendgroup.countOfDocuments === 1 ? this.translations.requestStatusDocument : this.translations.requestStatusDocuments).toLowerCase();
    } else if(this.itemGrouped){
      return this.actorInSendgroup.documentName;
    } else {
      return this.actorInSendgroup.documentName;
    }
  }

  public performActionForAnId(actionName: string, actor: ActorStatusInformationModel) {
    switch (actionName) {
      case RequestStatusActionsEnum.editPerson:
        this.requestStatusService.getRightActor(actor.inviteId);
        this.requestStatusService.editPerson(actor);
        break;
      case RequestStatusActionsEnum.changeDeadline:
        this.requestStatusService.getRightActor(actor.inviteId);
        // this.requestStatusService.changeDeadline();
        break;
      case RequestStatusActionsEnum.changeDeadlineDays:
        this.requestStatusService.getRightActor(actor.inviteId);
        // this.requestStatusService.changeDeadlineDays();
        break;
      case RequestStatusActionsEnum.resendRequest:
        this.requestStatusService.getRightActor(actor.inviteId);
        this.requestStatusService.resendRequestInviteForActor(actor);
        break;
      case RequestStatusActionsEnum.openPreview:
        // if(this.request.documentInfo.findIndex(document => document.id === id) > -1){
        //   let document = this.request.documentInfo[this.request.documentInfo.findIndex(document => document.id === id)]; 
        //   this.requestStatusService.openPreview(document);
        // }
        break;
      case RequestStatusActionsEnum.openEmailPreview:
        this.requestStatusService.getRightActor(actor.inviteId);
        this.requestStatusService.openEmailPreview(actor);
        break;
      default:
        break;
    }
  }

  public returnStatus(): string {
    let mainOrbStyle = "font-style-";
    if (!this.actorInSendgroup) {
      return mainOrbStyle + this.InfoStyle;
    }
    if (this.actorInSendgroup) {
      if (this.actorInSendgroup.actorStatus == ActorStatusEnum.Completed) {
        return mainOrbStyle + this.SuccessStyle;
      }
      if (this.actorInSendgroup.actorStatus == ActorStatusEnum.Pending) {
        return mainOrbStyle + this.WarningStyle;
      }
      if (this.actorInSendgroup.actorStatus == ActorStatusEnum.Error) {
        return mainOrbStyle + this.DangerStyle;
      }
      if (this.actorInSendgroup.actorStatus == ActorStatusEnum.TriggeredSpecialStatus) {
        return mainOrbStyle + this.DangerStyle;
      }
      if (this.actorInSendgroup.actorStatus == ActorStatusEnum.Waiting) {
        return mainOrbStyle + this.InfoStyle;
      }
      return mainOrbStyle + this.InfoStyle;
    }
    return mainOrbStyle + this.InfoStyle;
  }
}

@Component({
  selector: 'mobile-request-status-sendgroup-action-row',
  templateUrl: './mobile-request-status-sendgroup-action-row.component.html',
})

export class MobileRequestStatusSendgroupActionsComponent extends MobileRequestStatusSendgroupActionsTableClass {
  constructor(
    protected override requestStatusService: RequestStatusService, protected override requestBuilderService: RequestBuilderService, protected override translateService: TranslateService) {
    super(requestStatusService, requestBuilderService, translateService);
  }
}