import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DocumentActor } from '../../models/actors';
import { UpdateExistingRequestActorModel } from 'src/app/models/api/update-existing-actor.model';

@Injectable({
  providedIn: 'root'
})
export class PersonApiClient {

  constructor(private httpClient: HttpClient) { }

  getDossierPersonWithActions(dossierId: string, dossierPersonId: string){
    return this.httpClient.get(environment.apiUrl + "/requests/"+ dossierId + "/persons/" + dossierPersonId);
  }

  async addDossierPerson(dossierId: string, dossierPerson: DocumentActor){
    return this.httpClient.post(environment.apiUrl + "/requests/"+ dossierId + "/persons", dossierPerson);
  }
  
  async updateDossierPerson(dossierId: string, dossierPerson: DocumentActor){
    return this.httpClient.put(environment.apiUrl + "/requests/"+ dossierId + "/persons/" + dossierPerson.dossierPersonId, dossierPerson)
  }

  async removeDossierPerson(dossierId: string, dossierPerson: DocumentActor){
    return this.httpClient.delete(environment.apiUrl + "/requests/"+ dossierId + "/persons/" + dossierPerson.dossierPersonId)
  }

  async changeOrder(dossierId: string, orderPersons: DocumentActor[]) {
    return this.httpClient.post(environment.apiUrl + "/requests/" + dossierId + "/persons/ChangeOrder", orderPersons);
  }

  async getRequestsForPerson(requestGuid: string, personId: string){
    return this.httpClient.get<UpdateExistingRequestActorModel[]>(environment.apiUrl + "/requests/" + requestGuid  + "/persons/" + personId + "/GetRequestsForPerson")
  }
}
