import { EventEmitter, Injectable, Output, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastModel } from '../components/general/toast/toast.model';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastModel[] = [];
  translations = <any>{};
  // specific bools to delay certain non unique toasts to prevent them stacking when spamming buttons
  private showInvalidToasts = true;
  private showSavedToasts = true;
  private showSuccessToasts = true;

  private defaultDelay = 5000; // MS delay for visibility Toasts

  @Output() toastsUpdated = new EventEmitter<any>();

  constructor(private translateService: TranslateService) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations() {
    this.translateService.get([
      'General.Alert.ViewLatestUpdates',
      'General.Alert.ImpactNone',
      'General.Alert.ImpactMinor',
      'General.Alert.ImpactMajor',
      'General.Alert.ImpactCritical',
      'General.Alert.ImpactMaintenance',
      'General.Alert.StatusInvestigating',
      'General.Alert.StatusIdentified',
      'General.Alert.StatusMonitoring',
      'General.Alert.StatusResolved',
      'Toasts.Saved',
      'Toasts.Error',
      'Toasts.Warning',
      'Toasts.InvalidFormFields',
      'Toasts.ChangesSaved',
      'Toasts.PaymentRequiredHeader',
      'Toasts.PaymentRequiredContent',
      'Toasts.Copied',
      'Toasts.SuccessfullCopied',
      'Toasts.NewVersion',
      'Toasts.PatchNoteLink',
      'Toasts.PleaseWait',
      'Toasts.PleaseContinue'
    ]).subscribe(translation => {
      this.translations.viewLatestUpdates = translation['General.Alert.ViewLatestUpdates'];
      this.translations.impactNone = translation['General.Alert.ImpactNone'];
      this.translations.impactMinor = translation['General.Alert.ImpactMinor'];
      this.translations.impactMajor = translation['General.Alert.ImpactMajor'];
      this.translations.impactCritical = translation['General.Alert.ImpactCritical'];
      this.translations.impactMaintenance = translation['General.Alert.ImpactMaintenance'];
      this.translations.statusInvestigating = translation['General.Alert.StatusInvestigating'];
      this.translations.statusIdentified = translation['General.Alert.StatusIdentified'];
      this.translations.statusMonitoring = translation['General.Alert.StatusMonitoring'];
      this.translations.statusResolved = translation['General.Alert.StatusResolved'];
      this.translations.toastSaved = translation['Toasts.Saved'];
      this.translations.toastChangesSaved = translation['Toasts.ChangesSaved'];
      this.translations.toastError = translation['Toasts.Error'];
      this.translations.Warning = translation['Toasts.Warning'];
      this.translations.toastsInvalidFormFields = translation['Toasts.InvalidFormFields'];
      this.translations.PaymentRequiredHeader = translation['Toasts.PaymentRequiredHeader'];
      this.translations.PaymentRequiredContent = translation['Toasts.PaymentRequiredContent'];
      this.translations.toastsCopied = translation['Toasts.Copied'];
      this.translations.toastsSuccessfullCopied = translation['Toasts.SuccessfullCopied'];
      this.translations.newVersion = translation['Toasts.NewVersion'];
      this.translations.patchNoteLink = translation['Toasts.PatchNoteLink'];
      this.translations.PleaseWait = translation['Toasts.PleaseWait'];
      this.translations.PleaseContinue = translation['Toasts.PleaseContinue'];
    });
  }
  show(content: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ content, ...options });
    this.toastsUpdated.emit();
  }

  async remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }

  showDefault(header: string, content: string) {
    this.show(content, { classname: 'bg-primary', delay: this.defaultDelay, header: header, autohide: true, date: new Date() });
  }

  showSuccess(header: string, content: string) {
    this.show(content, { classname: 'bg-success', delay: this.defaultDelay, header: header, autohide: true, date: new Date() });
  }
  showSuccessByKey(header: string, content: string) {
    let translatedHeader = header != null && header != undefined && header != "" ? this.translateService.instant(header) : "";
    let translatedContent = content != null && content != undefined && content != "" ? this.translateService.instant(content) : "";
    this.show(translatedContent, { classname: 'bg-success', delay: this.defaultDelay, header: translatedHeader, autohide: true, date: new Date() });
  }
  /**
   * Displays a SHORT LIVED 'Success' Toast, meant to notify the user that auto saves are happening
   */
  showSaved() {
    let delaySavedFieldToast = 2000; // MS we delay the next toast as long as we show the previous
    if (this.showSavedToasts) {
      this.showSavedToasts = false;
      this.show(this.translations.toastChangesSaved, { classname: 'bg-success', delay: delaySavedFieldToast, header: this.translations.toastSaved, autohide: true, date: new Date() });
      setTimeout(() => { this.showSavedToasts = true; }, delaySavedFieldToast);
    }
  }
  /**
   * Displays a 'Error' Toast with an invalid fields message. 
   * Gated with a delay to prevent submit spam
   */
  async showInvalidFields() {
    let delayInvalidFieldToast = 2000; // MS we delay the next toast as long as we show the previous
    if (this.showInvalidToasts) {
      this.showInvalidToasts = false;
      this.show(this.translations.toastsInvalidFormFields, { classname: 'bg-danger', delay: delayInvalidFieldToast, header: this.translations.toastError, autohide: true, date: new Date() });
      setTimeout(() => { this.showInvalidToasts = true; }, delayInvalidFieldToast);
    }
  }
  /**
   * Displays a 'Success' Toast with anYoyu copied message. 
   * Gated with a delay to prevent spam
   */
  showCopied() {
    let delaySuccessToast = 2000; // MS we delay the next toast as long as we show the previous
    if (this.showSuccessToasts) {
      this.showSuccessToasts = false;
      this.show(this.translations.toastsSuccessfullCopied, { classname: 'bg-success', delay: delaySuccessToast, header: this.translations.toastsCopied, autohide: true, date: new Date() });
      setTimeout(() => { this.showSuccessToasts = true; }, delaySuccessToast);
    }
  }

  showWarning(header: string, content: string) {
    this.show(content, { classname: 'bg-warning', delay: this.defaultDelay, header: header, autohide: true, date: new Date() });
  }

  showError(header: string, content: string, delay: number = this.defaultDelay) {
    this.show(content, { classname: 'bg-danger', delay: delay, header: header, autohide: true, date: new Date() });
  }
  showErrorByKey(contentTranslationKey: string, delay: number = this.defaultDelay) {
    let translatedContent = this.translateService.instant(contentTranslationKey);
    this.show(translatedContent, { classname: 'bg-danger', delay: delay, header: this.translations.toastError, autohide: true, date: new Date() });
  }
  showErrorByKeyNoAutoHide(contentTranslationKey: string) {
    let translatedContent = this.translateService.instant(contentTranslationKey);
    this.show(translatedContent, { classname: 'bg-danger', delay: this.defaultDelay, header: this.translations.toastError, autohide: false, showCloseButton: true, date: new Date() });
  }


  showStatusAlerts(listOfIssues: any[], lastSeenStatusPageItemId: number) {
    for (let issue of listOfIssues) {
      let incidentUpdate = issue.incident_updates[0];
      if (issue.incident_updates.findIndex((s: any) => new Date(s.updated_at).getTime() === lastSeenStatusPageItemId) > -1 && incidentUpdate.status === "resolved" && new Date() > new Date(new Date(incidentUpdate.display_at).setDate(new Date(incidentUpdate.display_at).getDate() + 1))) return;

      let toastMessage: string = incidentUpdate.body;
      if (incidentUpdate.body.length > 100) toastMessage = toastMessage.substring(0, 100) + '... <br /><br /><a href="' + issue.shortlink + '" target="_blank" id="alert_link" rel="noopener" translate>' + this.translations.viewLatestUpdates + '</a>';
      let header: string = issue.name;
      this.showStatusAlert(header, toastMessage, issue.impact, incidentUpdate.status, new Date(incidentUpdate.updated_at), issue.id, incidentUpdate.id, new Date(issue.scheduled_for), new Date(issue.scheduled_until));
    };

    // If toast no longer has to be shown, remove it from the list.
    this.toasts.forEach((toast) => {
      if (toast.incidentUpdateId && (listOfIssues.findIndex((issue) => issue.incident_updates[0].id == toast.incidentUpdateId) < 0)) {
        this.toasts = this.toasts.filter(t => t !== toast);
      }
    });
  }
  async showStatusAlert(header: string, content: string, impact: string, status: string, date: Date, issueId: number, incidentUpdateId: number, scheduled_for: Date, scheduled_until: Date) {
    if (this.toasts.findIndex((t) => t.incidentUpdateId == incidentUpdateId) > -1) return;
    if (this.toasts.findIndex((t) => t.issueId == issueId && t.incidentUpdateId != incidentUpdateId) > -1) await this.remove(this.toasts.find((t) => t.issueId == issueId));
    let className: string = "";
    switch (impact) {
      case "critical":
        className = "bg-danger";
        break;
      case "major":
      case "minor":
        className = "bg-warning";
        break;
      default:
        className = "bg-primary";
        break;
    };
    if (status === "resolved") className = "bg-success";
    this.show(content, { classname: className, header: header, autohide: false, date: date, issueId: issueId, incidentUpdateId: incidentUpdateId, showDateTime: true, showCloseButton: true, showScheduleTime: impact === "maintenance" && status !== "completed", status: status, scheduled_for: scheduled_for, scheduled_until: scheduled_until });
  }


  // Specific toasts:




  /** Displays a lasting toast that tells the user when the version of his storage, no longer matches the web app
   * or in less tech terms, when a new version has been deployed
   * */
  DisplayNewVersion(newVersion: string, forPatchNotesUrl: string) {
    this.show(
      this.translateService.instant('Toasts.PlatformUpdated', { toVersion: newVersion })!,
      { urlToLink: forPatchNotesUrl, urlTitleToDisplay: this.translations.patchNoteLink, classname: 'bg-info', delay: this.defaultDelay, header: this.translations.newVersion, autohide: false, showCloseButton: true, date: new Date() });
  }

  ShouldShowNewVersionMessage(oldVersion: string, newVersion: string)
  {
    return oldVersion.split(".")[1] != newVersion.split(".")[1]
  }

  /** Displays a special warning, that tells the user that his digipoort/sbr nexus is set to a testing environment */
  displayEnvironmentWarning(serviceName: string, environmentName: string) {
    this.show(
      this.translateService.instant('Toasts.NonProductionEnvironment', { service: serviceName, environment: environmentName })!,
      { classname: 'bg-warning', delay: this.defaultDelay, header: this.translations.Warning, autohide: false, showCloseButton: true, date: new Date() });
  }

  /**
   * Shows a very "Special" Toats that your credits are insufficient/ dat je gewoon moet lappen makker. 
   * I did not use the error where i use this since maybe we want to expand this in the future with a Special image/link/longer delay since this requires immediate user interaction
   */
  showPaymentRequiredToast() {
    let delayInMs = 10000; // 10 seconds
    this.showError(this.translations.PaymentRequiredHeader, this.translations.PaymentRequiredContent, delayInMs);
  }

  /**
   * Show a document LOCKED toast for the given Document Name
   * @param documentName 
   */
  singleDocumentLockedForSigning(documentName: string) {
    this.show(
      this.translateService.instant('Toasts.DocumentLockedForSigning', { documentName: documentName })!,
      { classname: 'bg-warning', delay: this.defaultDelay, header: this.translations.PleaseWait, autohide: false, showCloseButton: true, date: new Date(), showDateTime: true });
  }

  /**
   * Show a document UNLOCKED/AVAILABLE toast for the given Document Name
   * @param documentName 
   */
  singleDocumentUnlocked(documentName: string) {
    this.show(
      this.translateService.instant('Toasts.DocumentUnlocked', { documentName: documentName })!,
      { classname: 'bg-success', delay: this.defaultDelay, header: this.translations.PleaseContinue, autohide: false, showCloseButton: true, date: new Date(), showDateTime: true });
  }
}
