import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PagesApiClient {

  constructor(private httpClient: HttpClient) { }

  async getPage(dossierId: string, documentId: string, pageNr: number) {
    return this.httpClient.get(environment.apiUrl + "/requests/" + dossierId + "/documents/" + documentId + "/pages/" + pageNr, { responseType: 'blob' })
  }

  async insertPageBefore(dossierId: string, documentId: string, pagenr: number, formData: FormData) {
    return this.httpClient.post(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/pages/" + pagenr +"/InsertPageBefore", formData)
  }

  async importPageBefore(dossierId: string, documentId: string, pagenr: number, provider: string, parentId: string, fileId: string) {
    return this.httpClient.post(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/pages/" + pagenr +"/ImportBefore", {"provider": provider, "drive": parentId, "itemId": fileId});
  }

  async deletePages(dossierId: string, documentId: string, pagesToDelete: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { pages: pagesToDelete }
    };
    return this.httpClient.delete(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/pages", httpOptions)
  }

  async changeBackground(dossierId: string, documentId: string, papertypes: any) {
    return this.httpClient.post(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/pages/Backgrounds",
    {
      backgrounds: papertypes
    })
  }

  async getThumbnail(dossierId: string, documentId: string, page: number) {
    return this.httpClient.get(environment.apiUrl + "/dossiers/" + dossierId + "/documents/" + documentId + "/pages/" + page + "/thumb", { responseType: 'blob' })
  }
}
