/**
 * Holds the selectable options for Identity providers
 */
export class IdentityProviderConstants {

    /**
     * The standard - no IDP chosen
     */
    static Default: string = 'Default';
    /**
     * The identity provider for Idin/VerifyRealIdentity
     */
    static IDIN: string = 'Bluem';
    /**
     * Vidua AUTHENTICATION - this does not return a Vidua Proof file
     */
    static Vidua_Authentication: string = 'vidua_Authentication';
    /**
     * Vidua IDENTIFICATION - this returns a Vidua Proof file
     */
    static Vidua_Identification: string = 'vidua_Identification';
}