import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import saveAs from 'file-saver';
import { ToastService } from './toast.service';
import { DocumentApiClient } from './document.apiclient';
import { DocumentForDownloadModel } from '../models/api/document-for-download.model';

/**
 * Service that you can use to start Downloading of Files
 */
@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  translations = <any>{};

  constructor(private translateService: TranslateService, private toastService: ToastService, private documentService: DocumentApiClient) {
    this.getTranslations();
    translateService.onLangChange.subscribe(() => {
      this.getTranslations();
    });
  }

  getTranslations() {
    this.translateService.get([
      'Request.View.PerformActionComplete.PreparingDocument',
      'Request.View.PerformActionComplete.Signing',
      'Request.View.PerformActionComplete.Signed',
      'Request.View.PerformActionComplete.ErrorStartingSignflow',
      'Request.View.PerformActionComplete.GivePermissionQTSPApp',
      'Request.View.PerformActionComplete.PreparingFiling',
      'Request.View.PerformActionComplete.GeneratingSignature',
      'Error.DefaultErrorTitle',
      'Error.EngineErrorDefault',
      'Error.Engine.FailedDependency',
      'Request.View.SigningDialog.ESeal',
      'Request.View.SigningDialog.Token',
      'Request.View.SigningDialog.TokenAgent',
      'Request.View.SigningDialog.Test',
      'Toasts.RequestsDownloadError',
      'Toasts.GetDocumentError',
      'Toasts.DownloadTitle',
      'Toasts.DownloadBody',
      'Toasts.Error',
      'Request.View.ReceivingEntity.Digipoort',
      'Request.View.ReceivingEntity.SBRNexus'
    ]).subscribe(translation => {
      this.translations.PreparingDocument = translation['Request.View.PerformActionComplete.PreparingDocument'];
      this.translations.Signing = translation['Request.View.PerformActionComplete.Signing'];
      this.translations.Signed = translation['Request.View.PerformActionComplete.Signed'];
      this.translations.ErrorStartingSignflow = translation['Request.View.PerformActionComplete.ErrorStartingSignflow'];
      this.translations.GivePermissionQTSPApp = translation['Request.View.PerformActionComplete.GivePermissionQTSPApp'];
      this.translations.PreparingFiling = translation['Request.View.PerformActionComplete.PreparingFiling'];
      this.translations.GeneratingSignature = translation['Request.View.PerformActionComplete.GeneratingSignature'];
      this.translations.DefaultErrorTitle = translation['Error.DefaultErrorTitle'];
      this.translations.EngineErrorDefault = translation['Error.EngineErrorDefault'];
      this.translations.FailedDependency = translation['Error.Engine.FailedDependency'];
      this.translations.ESeal = translation['Request.View.SigningDialog.ESeal'];
      this.translations.Token = translation['Request.View.SigningDialog.Token'];
      this.translations.TokenAgent = translation['Request.View.SigningDialog.TokenAgent'];
      this.translations.Test = translation['Request.View.SigningDialog.Test'];
      this.translations.toastsRequestsDownloadError = translation['Toasts.RequestsDownloadError'];
      this.translations.toastsGetDocumentError = translation['Toasts.GetDocumentError'];
      this.translations.error = translation['Toasts.Error'];
      this.translations.DownloadTitle = translation['Toasts.DownloadTitle'];
      this.translations.DownloadBody = translation['Toasts.DownloadBody'];
      this.translations.Digipoort = translation['Request.View.ReceivingEntity.Digipoort'];
      this.translations.SBRNexus = translation['Request.View.ReceivingEntity.SBRNexus'];
    });
  }
  async download(requestGuid: string, documentModels: DocumentForDownloadModel[]) {

    this.toastService.showDefault(this.translations.DownloadTitle, this.translations.DownloadBody);
    for (let documentModel of documentModels) {
      try {
        if (documentModel.isHtml) {
          let downloadRequest = await this.documentService.downloadHtml(requestGuid, documentModel.identifier);
          let file = await lastValueFrom(downloadRequest);
          if (file && file.body && file.headers) {
            let filename = file.headers.get("Content-Disposition")!.replace("attachment; filename=", "");
            this.saveFile(file.body, filename);
          }
        } else {
          let downloadRequest = await this.documentService.downloadDocument(requestGuid, documentModel.identifier);
          let file = await lastValueFrom(downloadRequest);
          if (file && file.body && file.headers) {
            let filename = file.headers.get("Content-Disposition")!.replace("attachment; filename=", "");
            this.saveFile(file.body, filename);
          }
        }
      } catch (error: any) {
        this.toastService.showError("" + this.translations.error + "", "" + this.translations.toastsRequestsDownloadError + "");
      }
    }
  }

  private saveFile(blobContent: Blob, fileName: string) {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

}
