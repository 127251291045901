<div class="modal-body p-0 p-lg-2">
    <div ngbAutofocus class="d-flex row-cols-12 mb-2">
        <b class="d-block d-lg-none px-3 pt-4 pb-2" translate="Dialog.Add{{action}}Action"></b>
        <h3 class="d-none d-lg-block title" translate="Dialog.Add{{action}}Action"></h3>
    </div>

    <div class="pt-1 px-3 pb-4 p-lg-4 pt-lg-0 ">
        <span class="d-flex mb-1" translate="Dialog.SelectPersons"></span>
        <div class="mb-3">
            <app-checklist [useCheckboxes]="true" (selectedPersonIds)="setSelectedPerson($event)" [action]="action"></app-checklist>
        </div>
        <div class="col-12 d-table w-100">
            <span class="float-start"><button type="button" class="btn btn-sm btn-outline-primary" (click)="close()" translate="Button.Back"></button></span>
            <span class="float-end"><button type="button" class="btn btn-sm btn-primary" (click)="addAction()" translate="Button.Add"></button></span>
        </div>
    </div>
</div>