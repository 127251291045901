import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { DocumentModel, PreloadedPages, PreloadedPagesByDocument } from "src/app/models/document.model";
import { LoggingService } from "src/app/services/logging.service";
import { DomSanitizer } from "@angular/platform-browser";
import { PagesApiClient } from "src/app/services/api-clients/pages.api-client";
import { SingleOrDefault } from "../_helpers/ArrayExtensionMethodHelper.functions";

@Injectable({ providedIn: 'root' })
export class DocumentPagesService {

    private _alreadyLoadedPagesByDocument: PreloadedPagesByDocument[] = [];

    constructor(private loggingService: LoggingService, private sanitizer: DomSanitizer,
        private pageService: PagesApiClient) { }

        public async getAlreadyLoadedPages(requestguid: string, documentGuid: string): Promise<PreloadedPagesByDocument> {
        // existing?
        let documentPagesModel = SingleOrDefault(this._alreadyLoadedPagesByDocument, "documentId", documentGuid);
        if (documentPagesModel) {
            return documentPagesModel;
        }
        // instantiate the FIRST page and return:
        await this.requestPage(requestguid, documentGuid, 1);
        return SingleOrDefault(this._alreadyLoadedPagesByDocument, "documentId", documentGuid)!;
    }

    public async requestPage(requestGuid: string, documentGuid: string, pageId: number): Promise<PreloadedPagesByDocument> {
        let documentPagesModel = SingleOrDefault(this._alreadyLoadedPagesByDocument, "documentId", documentGuid);
        if (documentPagesModel) {
            var existingPage = SingleOrDefault(documentPagesModel.preloadedPages, "PageId", pageId);
            if (existingPage) {
                return documentPagesModel;
            }
        }else{
            documentPagesModel = new PreloadedPagesByDocument();
            documentPagesModel.documentId = documentGuid;
            documentPagesModel.preloadedPages = [];
        }


        let getPage = await this.pageService.getPage(requestGuid, documentGuid, pageId);
        let page = await lastValueFrom(getPage);
        let pageImage = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(page));
        let preloadedPage: PreloadedPages = {
            PageId: pageId,
            PageUrl: pageImage
        }
        documentPagesModel.preloadedPages.push(preloadedPage);
        this._alreadyLoadedPagesByDocument.push(documentPagesModel);
        return documentPagesModel;
    }

    // private async loadPage(requestGuid: string, doc: DocumentModel, pageId: number) {
    //     try {
    //         if (pageId < 1) return;
    //         if (pageId <= doc.pages) {
    //             let preloadedPage: PreloadedPages = {
    //                 PageId: pageId,
    //                 PageUrl: null
    //             }

    //             let pagesIndex = this._alreadyLoadedPagesByDocument.findIndex(pages => pages.documentId === doc.id);
    //             if (pagesIndex > -1) {
    //                 let preloadedPagesIndex = this._alreadyLoadedPagesByDocument[pagesIndex].preloadedPages.findIndex(pp => pp.PageId === preloadedPage.PageId);
    //                 if (preloadedPagesIndex > -1) {
    //                     this._alreadyLoadedPagesByDocument[pagesIndex].preloadedPages[preloadedPagesIndex] = preloadedPage;
    //                 } else {
    //                     this._alreadyLoadedPagesByDocument[pagesIndex].preloadedPages.push(preloadedPage);
    //                 }
    //             }
    //             else {
    //                 let preloadedpagesByDocument: PreloadedPagesByDocument = {
    //                     documentId: doc.id,
    //                     preloadedPages: []
    //                 }
    //                 preloadedpagesByDocument.preloadedPages.push(preloadedPage);
    //                 this._preloadedPagesByDocument.push(preloadedpagesByDocument);
    //             }
    //             let getPage = await this.pageService.getPage(requestGuid, doc.id, pageId);
    //             let page = await lastValueFrom(getPage);
    //             let pageImage = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(page));

    //             if (this._preloadedPagesByDocument === undefined) this._preloadedPagesByDocument = [];
    //             if (pagesIndex > -1) {
    //                 let preloadPageIndex = this._preloadedPagesByDocument[pagesIndex].preloadedPages.findIndex(s => s.PageId === pageId);
    //                 if (preloadPageIndex > -1) {
    //                     this._preloadedPagesByDocument[pagesIndex].preloadedPages[preloadPageIndex].PageUrl = pageImage;
    //                 }
    //             }
    //         }
    //     }
    //     catch (error: any) {
    //         this.loggingService.logException(error);
    //     }
    // }


    // async getPages(requestGuid: string, doc: DocumentModel) {
    //     let loadPages = doc.pages < 5 ? doc.pages : this.defaultPreloadPages;
    //     for (let i: number = 1; i <= loadPages; i++) {
    //         try {
    //             let getPage = await this.pageService.getPage(requestGuid!, doc.id, i);
    //             let page = await lastValueFrom(getPage);
    //             let preloadedPage: PreloadedPages = {
    //                 PageId: i,
    //                 PageUrl: this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(page))
    //             }
    //             if (this._preloadedPagesByDocument === undefined) this._preloadedPagesByDocument = [];
    //             let pagesIndex = this._preloadedPagesByDocument.findIndex(pages => pages.documentId === doc.id);
    //             if (pagesIndex > -1) {
    //                 let preloadedPagesIndex = this._preloadedPagesByDocument[pagesIndex].preloadedPages.findIndex(pp => pp.PageId === preloadedPage.PageId);
    //                 if (preloadedPagesIndex > -1) {
    //                     this._preloadedPagesByDocument[pagesIndex].preloadedPages[preloadedPagesIndex] = preloadedPage;
    //                 } else {
    //                     this._preloadedPagesByDocument[pagesIndex].preloadedPages.push(preloadedPage);
    //                 }
    //             }
    //             else {
    //                 let preloadedpagesByDocument: PreloadedPagesByDocument = {
    //                     documentId: doc.id,
    //                     preloadedPages: []
    //                 }
    //                 preloadedpagesByDocument.preloadedPages.push(preloadedPage);
    //                 this._preloadedPagesByDocument.push(preloadedpagesByDocument);
    //             }

    //         }
    //         catch (error: any) {
    //             let pagesIndex = this._preloadedPagesByDocument.findIndex(pages => pages.documentId === doc.id);
    //             if (pagesIndex > -1) {
    //                 this._preloadedPagesByDocument[pagesIndex].complete = true;
    //             }
    //             break;
    //         }
    //     }
    // }


    // getPreloadedPagesByDocument(documentId: string) {
    //     let documentIndexForPreloadedPages = this._preloadedPagesByDocument.findIndex(doc => doc.documentId === documentId);
    //     if (documentIndexForPreloadedPages > -1) {
    //         return this._preloadedPagesByDocument[documentIndexForPreloadedPages].preloadedPages;
    //     }
    //     return [];
    // }

}
